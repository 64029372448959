
import { Component, Vue } from "vue-property-decorator";

import SporteventComponent from "./Sportevent.vue";

@Component({
  components: {
    SporteventComponent,
  },
})
export default class SporteventModalComponent extends Vue {}
