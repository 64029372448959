
import { Component, Vue, Prop } from "vue-property-decorator";
import i18n from "../../plugins/i18n";
import ToastService from "../../services/helper/ToastService";
import Multiselect from "vue-multiselect";
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";
import vMultiselectListbox from "vue-multiselect-listbox";

import Sportevent from "../../models/sportevent/Sportevent";
import SporteventService from "../../services/sportevent/SporteventService";

import AgePointCalculation from "../../models/sporteventCalculation/AgePointCalculation";
import AgePointCalculationService from "../../services/sporteventCalculation/AgePointCalculationService";

import StartgroupName from "../../models/sporteventCalculation/StartgroupName";
import StartgroupNameService from "../../services/sporteventCalculation/StartgroupNameService";

import Competition from "../../models/sporteventCalculation/Competition";
import CompetitionService from "../../services/sporteventCalculation/CompetitionService";

@Component({
  components: {
    Multiselect,
    datetime: Datetime,
    "v-multiselect-listbox": vMultiselectListbox,
  },
})
export default class SporteventComponent extends Vue {
  @Prop()
  public isModal!: boolean;

  public sportevent: Sportevent = new Sportevent();
  public allAgePointCalculation: AgePointCalculation[] = [];
  public allCompetitions: Competition[] = [];
  public allStartgroupName: StartgroupName[] = [];

  public loading = true;

  async mounted(): Promise<void> {
    this.loading = true;
    this.allAgePointCalculation = await AgePointCalculationService.getAll();
    this.allStartgroupName = await StartgroupNameService.getAll();
    this.allCompetitions = (await CompetitionService.getAll()).map(
      (c) => new Competition(c)
    );
    console.log("allCompetitions", this.allCompetitions);
    this.sportevent = await SporteventService.get();
    if (this.sportevent !== null && this.sportevent.id !== undefined) {
      console.log("LOAD");
      SporteventService.getWithApi(this.sportevent.id).then(
        async (item: Sportevent) => {
          this.sportevent = new Sportevent(item);
          this.sportevent.competitions = this.sportevent.competitions.sort(
            (a, b) =>
              a.name > b.name
                ? 1
                : a.name === b.name
                ? a.ageGenderGroup.ageFrom > b.ageGenderGroup.ageFrom
                  ? 1
                  : -1
                : -1
          );
          this.sportevent.competitions = this.sportevent.competitions.map(
            (c) => new Competition(c)
          );
          console.log(this.sportevent);
          this.loading = false;
        }
      );
    } else {
      console.log("NEW");
      this.sportevent = new Sportevent();
      if (this.sportevent.agePointCalculation.name == "") {
        this.sportevent.agePointCalculation = new AgePointCalculation(
          this.allAgePointCalculation.find(
            (AgePointCalculation) =>
              AgePointCalculation.id == "b0d6e705-7a94-43ac-b359-cded2433560a"
          )
        );
      }
      if (this.sportevent.startgroupName.name == "") {
        this.sportevent.startgroupName = new StartgroupName(
          this.allStartgroupName.find(
            (StartgroupName) =>
              StartgroupName.id == "28bbae3d-56e3-410f-bd07-babbd1d4f803"
          )
        );
      }
      console.log(this.sportevent);
      this.loading = false;
    }
  }

  async save(): Promise<void> {
    const sportevent = await SporteventService.save(this.sportevent);
    if (sportevent !== null) {
      this.sportevent = sportevent;
      ToastService.Success(i18n.tc("labels.saved"), this.sportevent.name);
      if (this.sportevent) {
        this.$store.dispatch("localstore_sportevent/set", this.sportevent);
      }
    }
  }

  getAgePointCalculationName(AgePointCalculation: AgePointCalculation): string {
    return AgePointCalculation.name;
  }

  StartGroupNameName(startgroupName: StartgroupName): string {
    return startgroupName.name;
  }
  getCompetitionName(competition: Competition): string {
    competition = new Competition(competition);
    return `${competition.getExtendedName()}`;
  }
  getCompetitionId(competition: Competition): string {
    competition = new Competition(competition);
    return `${competition.id}`;
  }
}
